import React, {Component} from 'react';
import './App.css';
import  'bootstrap/dist/css/bootstrap.min.css';
import CalculationForm from "./CalculationForm";

class App extends Component {
    render() {
        return (
            <div className="App container-fluid">
                <CalculationForm/>
            </div>
        );
    }
}

export default App;
