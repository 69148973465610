import React from 'react';

export default class CalcTableRow extends React.Component {
    render() {
        let {label, value} = this.props;

        return (
            <tr>
                <td>
                    {label}
                </td>

                <td>
                    {value} Kč
                </td>
            </tr>
        );
    }
}
