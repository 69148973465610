import React from 'react';
import CalcTableRow from "./CalcTableRow";

export default class CalcTable extends React.Component {
    render() {
        let {cost} = this.props;

        return (
            <table className="table">
                <thead>
                <tr>
                    <th>Úkon</th>
                    <th>Nacenění</th>
                </tr>
                </thead>

                <tbody>

                <CalcTableRow label="Projektové řízení" value={cost.management}/>
                <CalcTableRow label="Grafické úpravy a kódování" value={cost.graphic}/>
                <CalcTableRow label="Programové úpravy" value={cost.development}/>
                <CalcTableRow label="Testování" value={cost.testing}/>
                <CalcTableRow label="Celkem" value={cost.sum}/>

                {cost.discount < 0 &&
                <CalcTableRow label="Sleva" value={cost.discount}/>
                }

                {cost.discount < 0 &&
                <CalcTableRow label="Celkem po slevě" value={cost.afterDiscount}/>
                }

                </tbody>
            </table>
        );
    }
}
