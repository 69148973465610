import React from 'react';
import CalcTable from "./CalcTable";

class CalculationForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            senior: 0,
            grafik: 0,
            sleva: 0,
            sazba: 1500,
            koef: 1,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = parseInt(target.value);
        const name = target.name;

        this.setState({
            [name]: value ? value : ''
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        /* Get the text field */
        let copyText = document.getElementById("copy");

        /* Select the text field */
        copyText.select();

        /* Copy the text inside the text field */
        document.execCommand("copy");
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit} className="form-horizontal">
                <div className="row">
                    <div className="col-lg-6 col-xs-6 col-md-6">
                        <div className="form-group">
                            <label>
                                Senior:
                                <input type="text" name="senior" value={this.state.senior}
                                       onChange={this.handleChange}
                                       className="form-control"
                                />
                            </label>
                        </div>
                        <div className="form-group">
                            <label>
                                Grafik:
                                <input type="text" name="grafik" value={this.state.grafik}
                                       onChange={this.handleChange}
                                       className="form-control"
                                />
                            </label>
                        </div>
                    </div>

                    <div className="col-lg-5 col-xs-6 col-md-6">
                        <div className="form-group">
                            <label>
                                Poskytnutá sleva v %:
                                <input type="text" name="sleva" value={this.state.sleva}
                                       onChange={this.handleChange}
                                       className="form-control"
                                />
                            </label>
                        </div>
                        <div className="form-group">
                            <label>
                                Hodinová sazba:
                                <input type="text" name="sazba" value={this.state.sazba}
                                       onChange={this.handleChange}
                                       className="form-control"
                                />
                            </label>
                        </div>
                    </div>
                </div>

                {/*<label>*/}
                {/*Koeficient:*/}
                {/*<input type="text" name="koef" value={this.state.koef} onChange={this.handleChange}/>*/}
                {/*</label>*/}
                {/*<br/>*/}

                <div className="row">
                    <h3>Nacenění požadovaných úprav:</h3>
                    <CalcTable cost={this.createCosts()}/>
                </div>


                <div className="row">
                    <input type="submit" value="Kopírovat" className="btn btn-primary"/>

                    <div className="copy">
                        <textarea id="copy" cols={30} rows={10} value={this.generateTable()}
                                  readOnly={true}/>
                    </div>
                </div>
            </form>
        );
    }

    createCosts() {
        let sum = this.calcSum();
        let discount = this.calcDiscount(sum);

        return {
            development: this.calcDeveloper().toFixed(2),
            graphic: this.calcGraphic().toFixed(2),
            testing: this.calcTesting().toFixed(2),
            management: this.calcManagement().toFixed(2),
            sum: sum.toFixed(2),
            discount: discount.toFixed(2),
            afterDiscount: (sum + discount).toFixed(2),
        };
    }

    calcDiscount(sum) {
        return sum + (sum * -1 * (1 + this.state.sleva / 100));
    }

    calcSum() {
        return this.calcDeveloper() + this.calcGraphic() + this.calcManagement() + this.calcTesting();
    }

    calcManagement() {
        return (this.calcDeveloper() + this.calcGraphic()) * this.calcManagementCoeficient();
    }

    calcManagementCoeficient() {
        // ...
        // 75-100+ = 0.30
        // 50-75 = 0.25
        // 25-50 = 0.20
        //  0-25 = 0.15

        let sum = (this.state.senior + this.state.grafik);
        let coeficient = 0.15;
        for (let i = 1; i < (sum / 25); i++) {
            coeficient += 0.005;

            if (coeficient >= 0.5) {
                break;
            }
        }

        return coeficient > 0.15 ? coeficient : 0.15;
    }

    calcTesting() {
        return (this.state.senior * this.state.sazba * this.state.koef) * 0.1;
    }

    calcGraphic() {
        return this.state.grafik * this.state.sazba * this.state.koef;
    }

    calcDeveloper() {
        return this.state.senior * this.state.sazba * this.state.koef;
    }

    generateTable() {
        let cost = this.createCosts();

        return `
h2. Nacenění požadovaných úprav:

|| Úkon | Nacenění ||
|  Projektové řízení |  ${cost.management} Kč |
|  Grafické úpravy a kódování |  ${cost.graphic} Kč |
|  Programové úpravy |  ${cost.development} Kč |
|  Testování |  ${cost.testing} Kč |
| Celkem  |  ${cost.sum} Kč |
` + (this.state.sleva > 0 ? `| Celkem po slevě | ${cost.afterDiscount} Kč | ` : '')
            + (this.state.sleva > 0 ? `\n| Sleva | ${cost.discount} Kč | ` : '');
    }
}


// Vývojár I. stupen (senior)
// Vývojár II. stupen (junior)
// Grafik / Kódování
// Poskytnutá sleva v %
// Hodinová sazba

export default CalculationForm;
